.layout{
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 22vh;    
    position: relative;
    background-color: white;
}
.layout > div {
    margin: 21px 0;
}

.layout > div button,
.layout > div a{
    display: flex;
}

.layout > div svg{
    transition: all 500ms ease;
    opacity: 0.84;
}

.layout > div svg:hover{
    transform: scale(1.1);
    opacity: 0.9;
}

.saveToCart{
    position: relative;
}
.saveToCart span{
    background-color: #151515;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 9px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -45%;
    right: -45%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.menu{
    display: none;
}

.mobileMenu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 2cqh;
}
.mobileMenu > div{
    padding: 30px 15px;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: flex-end;
}
.mobileMenu > div  svg{
    opacity: 1 !important;
    fill: white ;
}

.mobileMenu a{
    color: white;
    padding: 10px;
}
.mobileMenu a{
    border-bottom: 1px solid white;
}

.insta{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 5px;
    font-weight: 700;
    font-size: 10px;
}

.insta svg{
    margin-right: 3px;
}



@media (max-width: 1200px) {
    .layout {
        z-index: 90;
        padding: 0 3vw 0 0 ;        
        flex-direction: row;
        justify-content: end;
        background-color: transparent;
    }
    .layout > div {
        margin: 0 1.5vw;
    }
    
    .insta{
        display: none;
    }
    .menu{
        display: flex;
        /* padding-bottom: 5px; */
    }
    
}

@media (max-width: 768px) {
    .layout {
        padding: 0 7vw 0 0;
    }
    .layout > div {
        margin: 0 2.5vw;
    }
   

}