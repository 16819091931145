

.layout{
    border-top: var(--frame-boder);
    display: flex;
    justify-content: space-between;
    padding: 23px 1vw  ;
}
.logo svg{
    margin-right: 4px;
}
.logo p{
    font-size: 10px;
}
.layout > div{
    display: flex;
    align-items: center;
}

.more  button,
.more  a{
    font-size: 11px;
    font-weight: 600;
    margin: 0 15px;
    transition: all 300ms ease;
}

.more button:hover,
.more a:hover{
    text-decoration: underline;
    text-underline-offset: 3px ;
}

@media (max-width: 768px) {
    .layout{
        flex-direction: column;
    }
    .logo{
        padding-bottom: 23px;
        justify-content: center;
        border-bottom: var(--frame-boder);
    }
    .more{
        padding-top: 13px;
        flex-direction: column;
    }
    .more button,
    .more a{
        margin: 10px 0;
    }
}