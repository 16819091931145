
.frame_1{
    z-index: 101;
    position: fixed;
    bottom: 7px;
    left: 20px;
    width: 37vw;
    min-width: 500px;
    height: 250px;
    background-color: #171717;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px ;
    clip-path: polygon( 1% 9%, 70% 2%, 78% 95%, 2% 90%);
}

.frame_1 h2{
    color: #e9e9e9;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 5px;
}
.frame_1 p{
    color: #e9e9e9;
    width: 70%;
    font-size: 12px;
}
.frame_1 a{
    color: #e9e9e9;
    white-space: nowrap;
    text-decoration: underline;
    text-underline-offset: 5px;
}
.frame_1 .btnFrame {
    display: flex;
    margin-top: 20px;
}
.frame_1 .btnFrame button{
    padding: 7px 27px;
    font-weight: 700;
    font-size: 11px;
}
.frame_1 .btnFrame button:first-child{
    background-color: white;
}
.frame_1 .btnFrame button:last-child{
    color: white;
}

.frame_2{
    z-index: 101;
    position: fixed;
    right: 0;
    top: 0;
    border-left: 2px solid gainsboro;
    width: 100vw;
    height: 100vh;
    background-color: rgba(83, 83, 83, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.frame_2 > div{
    min-width: 450px;
    width: 30vw;
    height: 65vh;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}
.frame_2 > div::-webkit-scrollbar{
    display: none;
}

.frame_2 .goBack{
    padding: 0px  37px;
    margin-bottom: 25px;
    border-bottom: var(--frame-boder);
}
.frame_2 .goBack button{
    font-size: 0.9em;
    padding: 6px 8px;
    color: white;
    margin: 10px 0;
}


.frame_2 .contentFrame{
    padding: 0 35px 35px;
}
.frame_2 .contentFrame h2{
    font-size: 17px;
    font-weight: bold;
}
.frame_2 .contentFrame p{
    font-size: 12.5px;
}

.frame_2  .contentFrame > div > div:first-child{
    margin: 40px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.frame_2 .contentFrame > div > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.frame_2 .contentFrame .swicht{
    margin-right: 20px;
    font-weight: 600;
    color: green;
    font-size: 12.5px ;
}

.frame_2 .contentFrame .outter{
    width: 35px;
    height: 23px;
    background-color: #171717;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 2px 2.5px;    
}
.frame_2 .contentFrame .inner{
    width: 15.5px;
    height: 15.5px;
    background-color: white;
    border-radius: 15px;
}

.frame_2 .contentFrame .modeOn {
    justify-content: flex-end;
    background-color: #171717;;
}
.frame_2 .contentFrame .modeOff{
    justify-content: flex-start;
    background-color: #d2d2d2;
}

.frame_2 .contentFrame button{
    padding: 9px 25px;
    font-size: 11px;
    font-weight: 600;
    margin-top: 30px;
    background-color: #171717; ;
    border-radius: 3px;
    color: white;
}


@media (max-width: 768px) {
    .frame_1{
        flex-direction: column;
        align-items: flex-start;
        padding: 3vh 5vw;
        min-width: auto;
        width: 100%;
        clip-path: none;
        left: 0;
        bottom: 0;
        height: 200px;
    }
    .frame_1 p {
        width: 100%;
    }
    .frame_1 .btnFrame{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }
    .frame_1 .btnFrame button{
        margin-right: 30px;
    }
    .frame_2 > div{
        width: 70vw;
        height: 75vh;
    }
}

@media (max-width: 577px) {
    .frame_1 p {
        font-size: 12px;
    }
    .frame_1 .btnFrame{
        width: 100%;
    }
    .frame_1 .btnFrame button{
        margin: 0 20px 0 0;
    }
    .frame_2 > div{
        width: 100vw;
        height: 100vh;
        min-width: auto;
    }
    .frame_2 .contentFrame{
        padding: 0 3vw 30px;
    }
}