.layout{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.container_1{
    padding: 5vh 0 20vh;
    /* margin-left: calc(var(--width-navbar) + 5vw); */
    margin-left: 250px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 64.5vw;
    min-height: 100vh;
}

.noneData{
    font-size: 15px;
    font-style: italic;
    letter-spacing: 1px;
}

.container_2{
    width: 100%;
}



 /* (min-width: 577px) and  */
 @media  (max-width: 1200px) {
    .layout{
        padding-top: var(--header-height-moblie);
    }
    .container_1{
        margin-left: 0;
        width: 84vw;
    }
}

@media (max-width: 768px) {
    .container_1{
        width: 100vw;
        padding: 1.5vw;
        margin-bottom: 100px;
        min-height: auto;
    }
}