


.mainFrame{
    width: calc(100% - var(--width-navbar));
}

.navbarFrame{
    position: fixed;
    top: 0;
    right: 0;
    width: var(--width-navbar);
    height: 100%;
    border-left:  var(--frame-boder);
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    z-index: 99;
}




@media  (max-width: 1200px) {
    .mainFrame{
        width: 100%;
    }
    .navbarFrame{
        position: fixed;
        top: 0;
        right: 0;
        width: 30%;
        height: var(--header-height-moblie);
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}