.layout{
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.301);
}

.layout > div{
    position: absolute;
    bottom: 0;
    right: calc(var(--width-navbar) + 30px);
    display: flex;
    align-items: center;
}
.layout  svg:first-child{
    width: 100px;
}
.layout  svg:last-child{
    width: 30px;
    height: 50px;
    object-fit: contain;
    margin-left: 10px;
    margin-top: 5px;
}
