.layout{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layout p{
    font-style: italic;
}

.layout > div > div{
    margin-top: 30px;
}
.layout a{
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 5px;
}
.layout a:hover{
    text-decoration: none;

}