.layout{
    width: 65%;
}

.item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 5px;
}

.item > div{
    display: flex;
    align-items: center;
}    
.item_img{
    position: relative;
    margin-right: 20px;
}

.item_img img{
    width: 75px;
    height: 75px;
    object-fit: cover;
    box-shadow: 0 0 0 1px #d9d9d9;
    border-radius: 9px;
}
.item_img p{
    position: absolute;
    right: 2.5px;
    top: 5px;
    transform: translate(50% , -50%);
    background-color: #292929;
    color: #e6e6e6;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item_content p{
    font-size: 13px;
    font-weight: 600;
    color: #686868;
}
.item_price p{
    font-size: 14px;
    font-weight: 600;
    color: #686868;
}

.total svg,
.item_price svg{
    fill: #686868;
}


/* total */
.total{
    margin-top: 20px;
    padding: 20px 10px 0 ;
    width: 100%;
    display: flex;
    border-top: 2px solid var(--gray-border-1);
    display: flex;
    flex-direction: column;
}
.total > div{
    display: flex;
    justify-content: space-between;
}

.total  p {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #686868;
}

@media (max-width: 1200px) {
    .layout{
        width: 50vw;
        min-width: 330px;
    }
}

