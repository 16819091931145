body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


:root{
  --gray-border-1: #e4e4e4;
  --frame-boder: 1.5px solid #e4e4e4;
  --width-navbar:  83px; 
  --header-height-moblie : 73px
}


*,
*::after,
*::before{
  margin: 0;
  padding: 0;
  letter-spacing: 0.06em;
  line-height: 1.6;
  text-decoration: none;
  list-style: none;
}

*{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  letter-spacing: 0.3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: #353535;
}

html{
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: auto;
}
::-webkit-scrollbar{
  width: 14px;
}

::-webkit-scrollbar-track{
  background-color: rgba(211, 211, 211, 0.8);
}
::-webkit-scrollbar-thumb{
  background-color:  rgba(128, 128, 128, 0.4);
}
::-webkit-scrollbar-thumb:hover{
  background-color:  rgba(128, 128, 128, 0.6);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::after{
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  border-top:  1px solid var(--gray-border-1);
}

button{
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
}

input,
textarea {
  border: none;
  outline: none;
}

span{
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
}


.paypal-button-label-container span{
  font-size: 13px !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}


