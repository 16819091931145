.layout{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}

.logoFrame{
    margin: 7vh 0 4vh;
    padding-left: 2vw;
    height: 33px;
}
.logoFrame svg{
    height: 100%;
    opacity: 0.9;
}

.menuFrame{
    width: 80%;
}
.link{
    padding-left: 2vw;
    display: flex;
    flex-direction: column;
}
.link a{
    background-color: transparent;
    border-bottom: 1.6px solid #f1f1f1a6;
    padding: 5px ;
    width: 100%;
    font-size: 13.5px;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: #3d3d3d;
    font-style: italic;
    transition: color 450ms  ease, padding-left 350ms 100ms ease, background-color 100ms  ease;
}
.link a:first-child{
    border-top: 1.6px solid #f1f1f1a6;
}
.link a:hover{
    background-color: #141011;
    padding-left: 40px;
    color: white;
}



.option{
    display: none;
}


 /* (min-width: 577px) and  */
@media (max-width: 1200px) {
    .layout {
        z-index: 10;
        width: 100%;
        font-size: 16px;
        background-color: white;
        border-bottom: var(--frame-boder);
        height: var(--header-height-moblie);
    }
    .logoFrame{
        height: 100%;
        padding: 0 29px;
        margin: 0;
        display: flex;
        align-items: center;
        /* padding: 0; */
    }
    .logoFrame svg{
        margin-left: 0;
        width: 18vw;
    }
    .menuFrame{
        display: none;
    }

}

@media (max-width: 768px) {
    .logoFrame svg{
        margin-top: 2px;
        min-width: 150px;
        width: 25vw;
        height: auto;
    }
}