
.container {
    position: relative;
    width: 100%;
    height: 100%;
    user-select: none;
}
.container::-webkit-scrollbar {
    display: none;
}

.slides {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.8s ease-out;
    width: 100%;
    height: 100%;
}
.slides > div{
    height: 100%;

}
.slides img{
    width: 100%;
    height: 100vh;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
    transition: all 600ms ease-out;
}

.slides img:not(:first-child){
    margin: 50px 0;
}


@media  (max-width: 992px) {
    .container {
        padding-top: var(--header-height-moblie);
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
    }
    .slides {
        flex-direction: row;
    }
    .slides > div{
        flex: 0 0 100%;
        width: 100vw;
        height: 100%;
        scroll-snap-align: start;
        scroll-snap-stop: always; 
    }
    .slides img{
        height: 93%;
        object-fit:contain;
    }
}