.layout{
    box-shadow: 0 0 0 1px #d9d9d9;
    border-radius: 5px;
}

.layout > div:not(:first-child){
    border-top: 1px solid #d9d9d9;
}



/* item */



.item label{
    display: flex;
    padding: 20px 40px;
    font-size: 13px;
    position: relative;
    border-radius: 3px;
    color: #6e6e6e;
}
.item label::before{
    position: absolute;
    content: "";
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color:  #d9d9d9;
    transition: all 350ms ease;
}
.item label::after{
    position: absolute;
    content: "";
    top: 50%;
    left: 22px;
    transform: translateY(-50%);
    height: 4.5px;
    width: 4.5px;
    border-radius: 50%;
    background-color:  transparent;
    transition: all 350ms ease;
}
.item input{
    display: none;
}
.item input:checked + label {
    background-color:  #202020;
    color: white;
}

.item input:checked + label::after{
    background-color:  #202020;
}

.item label{
    display: flex;
    align-items: center;
}
.item label img{
    width: 23px;
    margin: 0 10px;
}


/* infoBank */

.infoBank{
    margin-top: 30px;
    padding: 30px;
    background-color: #F7F7F7;
}
.infoBank{
    font-size: 13.5px;
}

.infoBank strong{
    color: #5a5a5a;
    font-weight: 600;
}