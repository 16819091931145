.layout{
    width: 20vw;
    margin: 15px 0.75vw 30px;
    display: flex;
    flex-direction: column;
}

.layout > div{
    position: relative;
}

.imgFrame{
    margin-bottom: 5px;
}

.imgFrame span{
    width: 100% !important;
    height: calc(20vw * 1.2) !important;
    border-radius: 3px;
}

.imgFrame span:nth-child(2){
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1 !important;
}
.imgFrame img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.imgFrame span:nth-child(2) img{
    opacity: 0;
    transition: 500ms opacity ease;
}

.activeImg{
    opacity: 1 !important;
    transition: 500ms opacity ease;
}

.status{
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    width: 67px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #151515;
    font-weight: 600;
    font-size: 13px;
    color: white;
    border-bottom-left-radius: 3px;
}

.name{
    font-weight: 600;
    font-size: 16px;
    color: #575757;
}
.label > div{
    display: flex;
}

    
.label p{
    font-weight: 600;
    font-size: 14px;
    color: #555555;
    display: flex;
}
.label p:nth-child(2){
    color: #b6b6b6;
    text-decoration: line-through;
    margin-left: 10px;
}

.label svg{
    fill: #555555;
}
.priceSale svg{
    fill: #b6b6b6;
}



@media  (max-width: 1200px) {
    .layout {
        width: 25vw;
        margin: 15px 1.5vw;
    }
    .imgFrame img{
        height: calc(25vw * 1.2);
    }

}

@media (max-width: 768px) {
    .layout {
        width: 50%;
        margin: 0 0 30px;
        padding: 1.5vw;
    }
    .imgFrame img{
        height: calc(49vw * 1);
    }
}
@media (max-width: 454px) {
    .name,
    .label p{
        font-size: 12px;
    }
}
@media (max-width: 376px) {
    .status{
        width: 35%;
        height: 17%;
        font-size: 11px ;
    }
   
}