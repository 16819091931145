.layout{
    width: 100%;
}


/* header */
.header{
    height: 70px;
    padding-left: 2vw;
    position: fixed;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: calc(100%  - var(--width-navbar));
}
.header button{
    font-weight: bold;
    color: rgb(92, 92, 92);
    font-style: italic;
    font-size: 17px;
    display: flex;
    align-items: center;
}
.header .logo{
    margin-left: 15px;
    width: 140px;
}
.navbarBtn{
    transition: all 500ms ease;
}
.navbarBtn:hover{   
    transform: scale(0.9);
}


.header svg{
    fill: rgb(44, 44, 44);
}


.navbar{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: -50px;
    left: 0;
    padding: 35px 2vw;
    width: 170px;
    height: 160px;
}
.navbar a{
    position: absolute;
    top: 0;
    font-style: italic;
    letter-spacing: 1.5px;
    font-weight: 500;
    font-size: 13.5px;
    /* border-bottom: 1.6px solid #2020206e; */
}
.navbar a:hover{
    text-decoration: underline;
    text-underline-offset: 5px;
}



/* main */
.main{
    width: 100%;
    display: flex;
}
.imgFrame{
    position: relative;
    width: 60% ;
    height: 100% ;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgFrame .imgShow{
    width: 100%;
    height: 100%;
}



/* cotentFrmae */
.contentFrame{
    width: calc(40vw -  var(--width-navbar) );
    height: 100%;
    display: flex;
    align-items: center;
    overflow-y: scroll;
    position: fixed;
    right: var(--width-navbar);
}
.contentFrame::-webkit-scrollbar{
    display: none;
}




.contentFrame > div {
    display: flex;
    flex-direction: column;
    width: 25vw;
}
.contentFrame > div > div{
    padding: 6px 20px;
    position: relative;
}
.contentFrame > div > div:not(:last-child){
    border-bottom:  1.5px solid #e9e9e9a8 ;
}

.contentFrame p,
.contentFrame button{
    font-size: 13.5px;
    color: #797979e5 ;   
}
.headerProduct h1{
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1.3px;
}

.headerProduct > div{
    display: flex;
}

.headerProduct p{
    color: #3f3f3f;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.3px;
}


.headerProduct > div p:nth-child(2){
    text-decoration: line-through;
    margin-left: 5px;
    color: #9b9b9b;
}

.description p{
    font-size: 12.3px ;
    font-weight: 500;
    color: #4d4d4d;
    letter-spacing: 0.5px;
}

.optionSize{
    display: flex;
    align-items: center;
}
.optionSize p{
    margin-right: 12px;
}

.optionSize button{
    position: relative;
    padding: 5px;
    margin: 0 8px;
    font-weight: 600;
}
.optionSize  .sizeOption_Active{
    color: #151515 ;
    font-weight: bold ;
}

.sizeOption_Active::after{
    position: absolute;
    content: "";
    bottom: 0px;
    height: 2.5px;
    border-radius: 20px;
    width: 0;
    background-color: #5c5c5c;
}

.sizeOption_Active::after{
    width: 8px;
}
.sizeOption_NoneActive::after{
    width: 0px;
}

.similar{
    display: flex;
    align-items: flex-end;
}
.similar > div{
    display: flex;
    margin-top: 25px;
}
.similar label{
    position: absolute;
    top: 7px;
    left: 20px;
    font-size: 13px;
    color: #797979e5 ; 
}
.similar a label{
    display: none;
    background-color: white;
    width: 60px;
}

.similar img{
    height: 45px;
    border-radius: 7px;
}
.similar a{
    display: flex;
    border-radius: 7px;
    margin-right: 9px;
    border:  1.5px solid #e4e4e4 ;
    transition: all 300ms ease;
}
.similar a:hover{
    border:  1.5px solid #cecece ;
}

.similar a:hover label{
    display: block;
}

.contentFrame .addToCart{
    background-color: #171717;
    color: white;
    font-weight: 600;
    font-size: 12.5px;
    padding: 10px 55px;
    margin: 10px 0;
    transition: all 300ms ease;
}
.contentFrame .addToCart:active{
    opacity: 0.5;
}

.contentFrame .sizeGuide{
    font-weight: 600;
    font-size: 13px;
}
.contentFrame .sizeGuide svg{
    fill: #797979e5;
    margin-left: 2px;
}
.contentFrame .sizeGuide:hover{
    text-decoration: underline;
    text-underline-offset: 5px;
    text-underline-position: 5px;
}
@media  (min-width: 1200px) {
    .imgFrame{
        padding-bottom: 100px;
    }
}

@media  (max-width: 1200px) {
    .header{
        padding-left: 29px;
    }
    .header .logo{
        margin-left: 0;
        width: 18vw;
        min-width: 150px;
    }
    .contentFrame{
        width: 40vw;
        right: 0;
        padding: 0 3vw;
    }
    .contentFrame > div {
        width: 100%;
    }
    .navbarBtn,
    .navbar{
        display: none !important;
        pointer-events: none;
    }
    .similar > div{
        margin: 5px 0;
    }
    .similar label{
        display: none;
    }
}
@media  (max-width: 992px) {
    .main{
        flex-direction: column;
    }
    .header{
        background-color: white;
        width: 100%;
        height: var(--header-height-moblie);
        border-bottom: var(--frame-boder);
    }
    .header span{
        display: none;
    }
    .imgFrame{
        width: 100%;
        height: auto;
    }
    .contentFrame{
        padding: 0 0 80px;
        width: 100%;
        background-color: white;
        position: static;
    }
    .contentFrame .addToCart{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 65px;
        margin: 0;
    }
}
