.overlay{
    width: calc( 100% - var(--width-navbar));
    height: 100%;
    background: rgba(143, 143, 143, 0.315);
    background-image: linear-gradient(to bottom right, #2d80aa63, #d9d9d95d);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 99;
    justify-content: flex-end;

}
.overlay::after{
    content: "";
    position: absolute;
    width: var(--width-navbar);
    height: 100%;
    transform: translateX(100%);
}

.layout{
    width: 35vw;
    height: 100%;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    justify-content: flex-end;
}
.layout > div::-webkit-scrollbar{
    display: none;
}

.layout > div{
    width: 100%;
    min-width: 530px;
    overflow-x: scroll;
    white-space: nowrap;
    background-color: white;
}

/* closeBtn */
.closeBtn{
    display: none;
    justify-content: end;
}
.closeBtn button{
    padding: 15px 20px;
}



/* list */
.list{
    width: 100%;
    height: 80%; 
    overflow-y: scroll;
    padding: 28px 0;
}
.list::-webkit-scrollbar{
    display: none;
}

.checkout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 20%;
    width: 100%;
    border-top: var(--frame-boder);
    padding-right: 28px;
    overflow-y: hidden;
}
.checkout > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.checkout > div p{
    text-align: right;
    display: flex;
}
.checkout > div p:first-child{
    font-size: 15px;
    font-weight: 700;
    color: #383838;
}
.checkout > div p:nth-child(2){
    font-size: 13px;
    color: rgb(124, 124, 124);
}

.checkout > div p > span{
    margin-left: 5px;
}


.checkout button{
    background-color: #1d1d1d;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: white;
    margin-top: 10px;
    padding: 8px 30px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    border-radius: 3px;
}

.afterElement{
    position: fixed;
    right:  0;
    top: 0;
    width: var(--width-navbar) ;
    height: 100vh;
}
.noneAcitve{
    display: none;
    pointer-events: none !important;
}



@media (max-width: 1200px) {
    .overlay{
        width: 100vw;
    }
    .layout{
        width: 60vw;
    }
  
}
@media  (max-width: 992px) {
    .overlay,
    .layout{
        justify-content: flex-start;
    }
}
@media (max-width: 768px) {
    .afterElement{
        display: none;
    }
    .closeBtn{
        display: flex;
    }
    .layout{
        width: 100% ;
        justify-content: flex-start;
    }
    .layout > div{
        min-width: auto
    }
    .list{
        padding-bottom: 100px;
    }
    .checkout{
        position: fixed;
        bottom: 0;
        height: auto;
        padding: 30px 0 0;
        background-color: white;
    }
    .checkout > div p{
        padding: 0 3vw;
        white-space: wrap;
    }
    .checkout .checkoutBtn{
        width: 100%;
    }
    .checkout button{
        height: 65px;
        width: 100%;
        font-size: 15px;
        border-radius: 0;
    }
}

