.layout{
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #6464641a;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout > div{
    width: 550px;
    height: 350px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 50px;
    background-color: black;
    position: relative;
    overflow: hidden;
}

.layout > div > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.closeBtn{
    position: absolute;
    right: 0;
    top: 0;
    padding: 9px;
    margin: 5px;
}
.closeBtn svg{
    fill: white
}

.top{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.top h2{
    font-weight: bold;
    font-size: 25px;
    color: white;
}


.center{
    display: flex;
    border: 2.5px solid rgba(255, 255, 255, 0.123);
    border-radius: 3px;
    padding: 5px;
    margin: 9px 0;
    overflow-x: hidden;
}

.center input{
    width: 250px;
    background-color: transparent;
    color: white;
}

.center button{
    background-color: white;
    padding: 5px 20px;
    border-radius: 3px ;
}

.bottom{
    margin-right: 50px;
    width: 85%;
}
.bottom p{
    font-size: 12px;
    color: white;
    font-style: italic;
}
.successBox{
    padding-right: 50px;
}
.successBox h2{
    font-size: 15px;
    color: white;
    margin-bottom: 10px;
}

.successBox p{
    font-size: 12px;
    color: white;
}
.successBox p span{
    text-decoration: underline;
    text-underline-offset: 3px;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .closeBtn{
        margin: 26px 15px;
    }
    .layout{
        width: 100vw;
        height: 200px;
    }
    .layout > div{
        width: 100%;
        height: 100%;
        padding: 20px 4vw;
    }
    .top{
        margin-bottom: 5px;
    }
    .center{
        width: 100%;
    }
    .center button{
        background-color: transparent;
    }
    .center button svg{
        fill: white;
    }
    .center input{
        width: 100%;
    }
    .bottom{
        width: 100%;
    }
}