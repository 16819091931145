.overlay{
    width: calc( 100% - var(--width-navbar));
    height: 100%;
    background: rgba(143, 143, 143, 0.315);
    background-image: linear-gradient(to bottom right, #2d80aa63, #d9d9d95d);
    position: fixed;
    top: 0;
    left:  0;
    display: flex;
    z-index: 99;
    justify-content: flex-end;
}
.overlay::after{
    content: "";
    position: absolute;
    width: var(--width-navbar);
    height: 100%;
    transform: translateX(100%);
}

.layout{
    width: 35vw;
    min-width: 530px;
    height: 100%;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    justify-content: flex-end;
}

.layout > div{
    overflow: scroll;
    height: 100%;
    background-color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.layout > div::-webkit-scrollbar{
    display: none;
}

.layout img{
    width: 90%;
    height: auto;
    object-fit: contain;
}

/* closeBtn */
.closeBtn{
    display: none;
    position: absolute;
    top: 3vh;
    right: 3vh;
}


@media (max-width: 1200px) {
    .overlay{
        width: 100vw;
    }
    .layout{
        width: 60vw;
        min-width: 500px;
    }
}

@media  (max-width: 992px) {
    .overlay,
    .layout{
        justify-content: flex-start;
    }
}
@media (max-width: 768px) {
    .overlay{
        z-index: 100;
    }
    .closeBtn{
        display: flex;
    }
  
}
@media (max-width: 577px) {
    .layout{
        width: 100% ;
        min-width: auto;
        justify-content: flex-start;

    }
}