

.layout{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    position: relative;
    background-color: rgb(14, 14, 14);
    display: flex;
    align-items: center;
    justify-content: center;
}
.layout .main{
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 50%;
}

.logoOutline{
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    left: -15px;
    right: -15px;
    pointer-events: none;
    opacity: 0.7;
}


.container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container > div{
    margin: 0 7vw;
    padding-top: 1vh;
}
.container > div button{
    color:  white;
    font-size: 23px;
    /* font-family: "Kanit", sans-serif; */
    font-weight: 800;
    font-style: italic;
    padding: 10px 15px 7px;
    position: relative;
    white-space: nowrap;
}

.container > div button::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120%;
    width: 0px;
    height: 1px;
    border-radius: 20px;
    background-color: transparent;
    transition: all 450ms ease-out;
}

.container > div button:hover:after{
    width: 120%;
    background-color: whitesmoke;
}

.effect_1{
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute !important;
    right: 0;
    top: 6.5%;
    width: 100vw;
}
.effect_2{
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute !important;
    right: 0;
    bottom: 6.5%;
    width: 100vw;
}


.info{
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 15px 3.5vw;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}
.info p,
.info a{
    color: white;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    margin-left: 30px;
    display: flex;
    align-items: center;

}

.info a{
    cursor: pointer;
}
.info p:hover,
.info a:hover{
    text-decoration: underline;
    text-underline-offset: 5px;
}

.info svg{
    fill: white;
    margin-right: 6px;
}

/* ????? */
.noneActive{
    position: relative;
    pointer-events: none;
    text-decoration-line: line-through;
}
.noneActive::after{
    content: "COMING SOON" !important;
    white-space: nowrap;
    color: brown;
    left: 45% !important;
    font-size: 13px;
    display: flex;
    position: absolute;
}

/* loading */
.loading{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgb(14, 14, 14);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading svg:first-child{
    width: 100px;
}
.loading svg:last-child{
    width: 50px;
    margin-top: 5px;
    margin-left: 10px;
}

@media (max-width: 577px) {
    .layout{
        padding-bottom: 3vh;
    }
    .container{
        flex-direction: column;
        padding-bottom: 5vh;
    }

}