.layout{
    display: flex;
    margin-bottom: 20px;
    border-radius: 5px;
    width: 100%;
    padding: 0 28px;
    overflow: hidden;
}

.imgFrame{
    width: 34%;
    margin-bottom: 10px;
    min-width: 180px;
}

.imgFrame img {
    width: 100%;
    object-fit: contain;
}

.contentFrame{
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    width: 76%;
    white-space: wrap;
}

.contentFrame > div{
    display: flex;
    flex-direction: column;
}
.contentFrame > div a{
    font-weight: 700;
    font-size: 15px;
}
.contentFrame > div p{
    font-size: 13px;
    font-weight: 400;
    color: #3f3f3f;
    display: flex;
    align-items: center;
}


.contentFrame > div svg{
    fill: #747474;
}
.contentFrame > div > div{
    display: flex;
    padding-top: 13px;
    align-items: center;
}
.contentFrame > div > div button{
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #f1f1f1;
}
.contentFrame > div > div:last-child p{
    width: 27px;
    margin: 0 5px;
    font-weight: bold;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contentFrame > div:nth-child(2) > p {
    border-bottom: 1.5px solid #f1f1f193;
    padding:  6px 0 ;
    margin-bottom: 6px;
}

.contentFrame .price{
    padding-top: 0;
}
.contentFrame .price p{
    font-weight: inherit;
} 
.contentFrame .price p:nth-child(2){
    text-decoration: line-through;
    opacity: 0.7;
}

@media (max-width: 500px) {
    .layout{
        padding: 0 3vw;
    }
    .imgFrame{
        min-width: 130px;
    }
    .contentFrame{
        padding-left: 3vw;
    }
}

