.layout{
    display: flex;
    box-shadow: 0 0 0 1px #d9d9d9;
    border-radius: 3px;
}

.layout input{
    padding: 10px 20px;
    width: 100%;
    font-weight: 600;
    color: #505050;
    background-color: transparent;
}
.errInput{
    box-shadow: 0 0 0 1px #c40a0a;
}
.errInput input{
    color: #c40a0a;
    transition: all 300ms ease;
}
.layout button{
    background-color: #202020;
    font-size: 13px;
    font-size: 600;
    color: white;
    padding: 0 30px;
    border-radius: 3px;
    transition:  all 300ms ease;
    white-space: nowrap;
}

.layout button:hover{
    padding: 0 28px;
}
.layout button:active{
    opacity: 0.7;
}

.layout .isDiscount{
    background-color: rgb(247, 247, 247);
}
