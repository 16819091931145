.layout{
    width: 100vw;
    display: flex;
}
.layout h2{
    padding-bottom: 15px;
    font-size: 17px;
}
.cart{
    width: 42%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    background-color: #F7F7F7;
    border-left: 2px solid #d9d9d9cb;
}
.cart > div{
    width: 100%;
    display: flex;
    justify-content: center;
}

.cartMobie{
    display: none;
}
.cartMobie button{
    text-decoration: underline;
    text-underline-offset: 5px;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 600;
}
.cartCloseBtn{
    display: none;
    position: absolute;
    top: 4vh;
    right: 4vh;
}

/* ------ */
.payment{
    padding-bottom: 20vh;
    width: 58%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.payment .logo{
    margin: 50px 0 ;
}
.payment .logo svg{
    width: 200px;
    cursor: pointer;    
}
.payment .logo > div p{
    display: none;
}

.payment div{
    position: relative;
} 
.payment > div{
    width: 500px;
}
.infoFrame > div{
    margin:  12px 0;

}
.input{
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    box-shadow: 0 0 0 1px #d9d9d9;
    height: 45px;
    border-radius: 5px;
    padding: 2px 10px;
}
.input label{
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: 0.3px;
    font-weight: 500;
    transition: all 300ms ease;
    color: rgb(121, 121, 121);
    user-select: none;
}

.input input,
.input textarea{
    width: 100%;
    font-size: 12px;
    font-weight: 500;
}

.input input:focus,
.input input:not(:placeholder-shown) + label,
.input textarea:not(:placeholder-shown) + label{
    font-weight: 600;
    color: rgb(59, 105, 91);
}

.input input:not(:placeholder-shown){
    padding-top: 16px;
}

.input input:not(:placeholder-shown) + label{
    font-size: 10px;
    top: -9px;
    transition: all 300ms ease;
}

.inline_1,
.inline_2{
    display: flex;
    justify-content: space-between;
}

.inline_1 .input:first-child{
    width: 68%;
    margin-right: 10px;
}
.inline_1 .input:last-child{
    width: 30%;
}
.inline_2 .input:not(:first-child){
    margin-left: 10px;
}

/* select */
.inline_2 label{
    font-size: 10px;
    top: -9px;
    pointer-events: none;
}
.inline_2 p svg{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.inline_2 > div{
    width: 100%;
    position: relative;
}

.inline_2  p{
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.inline_2  p span{
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 85%;
}
.inline_2  ul{
    z-index: 10;
    position: absolute;
    overflow-y: scroll;
    height: 40vh;
    width: 280px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    user-select: none;
}
.inline_2  ul li{
    cursor:  pointer;
    padding: 2px 10px;
    font-size: 12px;
}
.inline_2  ul li:hover{
    background-color: #F7F7F7;
}

.inline_2 ul > div{
    display: flex;
    align-items: center;
    padding: 5px 10px ;
    border-bottom: var(--frame-boder);
}
.inline_2 ul > div input{
    padding: 5px 10px !important;
}

.fullWidth > div{
    width: 100% !important;
}

/* subscribeInput */
.subscribeInput{
    display: inline-block;
    margin-top: 30px;
}
.subscribeInput input {
	display: none;
}
.subscribeInput label {
    display: flex;
    align-items: center ; 
    font-size: 12px;
    font-weight: 500;
    color: rgb(121, 121, 121);
}
.subscribeInput label .checkbox{
    margin-right: 10px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 1px #afafaf;
    background-color: transparent;
    transition: all 300ms ease;
}
.subscribeInput label .checkbox svg{
    opacity: 0;
}

.subscribeInput  input:checked + label .checkbox{
    background-color: #202020;
    box-shadow: 0 0 0 1px #202020;
}
.subscribeInput  input:checked + label .checkbox svg{
    opacity: 1;
}



/* methodPayment */
.methodPayment{
    margin-top: 50px;
}


/* .order */
.order{
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
} 
.order button{
    background-color: #202020;
    color: white;
    padding: 10px 30px;
    min-width: 100px;
    font-size: 12px;
    font-weight: 500;
    transition: all 500ms ease;
    border-radius: 5px;
}
.order button:hover{
    padding: 10px 28px;
}

.order button:active{
    opacity: 0.5;
}

/* noneValue */
.noneValue{
    box-shadow: 0 0 0 1px #c40a0a;
}
.noneValue input,
.noneValue span{
    color: #c40a0a;
}

/* styleNote */
.styleNote{
    height: auto;
}
.styleNote label{
    height: auto;
    top: 12px ;
}
.styleNote textarea{
    resize: none;
    height: auto;
    padding-top: 11px;
}
.styleNote textarea::-webkit-scrollbar{
    display: none;
}
.styleNote textarea:not(:placeholder-shown){
    padding-top: 21px;
}
.styleNote textarea:not(:placeholder-shown) + label{
    font-size: 10px;
    top: 8px;
    transition: all 300ms ease;
}

/* methodPayment */
.discount{
    margin-top: 50px;
}


@media (max-width: 1200px) {
    .layout{
        justify-content: center;
    }
    .cart,
    .payment{
        width: 100vw;
    }
  
    .payment .logo {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        background-color: white;
        border-bottom: var(--frame-boder);
        margin: 0;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .payment .logo > div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 500px;
    }
    .payment .logo > div p{
        display: block;
        font-size: 17px;
        font-weight: 600;
        margin-top: 5px;
    }
    .payment .logo > div svg{
        width: 18vw;
        min-width: 150px;
    }
    .payment .logo > div p span{
        margin-left: 5px;
    }

    .payment {
        padding-top: 100px;
    }
    .cartMobie{
        display: flex;
    }
    .cart{
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
    .cartCloseBtn{
        display: flex;
    }

}

@media (max-width: 577px) {
    .payment > div{
        width: 100%;
        padding: 0 5vw;
    }
    .inline_1,
    .inline_2{
        flex-direction: column;
        width: 100%;
    }
    .inline_1 > div,
    .inline_2 > div{
        width: 100% !important;
        margin: 0  !important;
    }
    .inline_1 > div:not(:first-child),
    .inline_2 > div:not(:first-child){
        margin: 12px 0 0!important;
    }
    .inline_2 ul{
        width: 100%;
    }

}